.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0084ff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    z-index: 10000;
}

.chat-button:hover {
    background-color: #005bb5;
}
.chat-notification-badge {
    position: fixed;
    bottom: 60px;
    right: 20px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
  }