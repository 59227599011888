.payment-content{
    display: flex;
    justify-content: center;
}
.payment-options {
    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 1fr); /* Adjust columns based on your preference */
    gap: 10px;
    width: 500px;
    padding: 20px;
    right: 0;
}

.option {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #050505a4; /* Card background color */
    padding: 15px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.option:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    z-index: 900;
}

.option img {
    max-width: 100%;
    max-height: 50px;
    object-fit: contain;
    z-index: 900;
}

.payment-container {
    /* background-color: #1d1d3d; */
    position: relative;
    /* float: left; */
    margin-left: 100px;
    color: white;
    padding: 0 20px;
    border-radius: 10px;
    width: 400px;
    font-family: Arial, sans-serif;
}
.payment-container h2 {
    z-index: 1000;
    color: white;
}

.amount-selection {
    display: flex;
    gap: 10px;
}

.amount-selection input,
.amount-selection select,
.promo-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    border-radius: 5px;
    margin-bottom: 10px;
    background-color:  #050505a4;
    color: white;
    outline: none;
    cursor: pointer;
    z-index: 1000;
}
/* Base styles for custom select */
.custom-select {
    position: relative;
    width: 100%; /* Adjust width to 100% to be responsive, or set a specific width if needed */
    max-width: 200px; /* Set a max-width to control the size */
}

.select-box {
    background-color: #050505a4;
    color: white;
    padding: 8px 12px;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-radius: 4px; /* Add border-radius for smoother corners */
}

/* Arrow styling */
.select-box::after {
    content: '▼'; /* Downward arrow */
    font-size: 12px;
    color: white;
    margin-left: 8px;
}

/* Options container hidden by default */
.options-container {
    position: absolute;
    top: 100%; /* Position it directly below the select box */
    left: 0;
    right: 0;
    background-color: #050505d0;
    border-radius: 4px;
    display: none; /* Hidden by default */
    flex-direction: column;
    max-height: 200px; /* Limit the height */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
}

/* Option styling */
.country-option {
    padding: 8px 12px;
    background-color: #050505a4;
    border-bottom: 1px solid #684343c5; /* Add separation between options */
    cursor: pointer;
    color: white;
}

/* Last option border styling */
.country-option:last-child {
    border-bottom: none; /* Remove border from the last option */
}

.country-option:hover {
    background-color: #5e05058f; /* Change color on hover */
}

/* Show options when dropdown is open */
.options-container.show {
    display: flex; /* Change from none to flex to show options */
}

  
  /* Open state */
  .custom-select.open .options-container {
    display: flex;
    z-index: 1100;
  }
  
.quick-select {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.quick-select button {
    padding: 8px 12px;
    background-color: #050505a4;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    border-radius: 5px; 
    color: white;
    cursor: pointer;
    transition: background 0.3s;
}

.quick-select button:hover {
    background-color:  #050505a4;
    z-index: 1000;
}

.bonus-slider {
    margin: 15px 0;
    position: relative;
}

.bonus-slider label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}

.slider-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 80%; /* Adjust this to control the width of the slider area */
    margin: 0 auto; /* Center the slider container */
}

.slider {
    flex: 1;
    max-width: 100%; /* Ensures the slider does not exceed the container */
}

.total-amount {
    background-color:   #050505a4;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    border-radius: 5px; /* Rounded corners */
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
    z-index: 1000;
}

.bonus-info {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.bonus-box {
    background-color:  #050505a4;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    border-radius: 5px; /* Rounded corners */
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    flex: 1;
}

.bonus-details {
    flex: 2;
    background-color:#050505a4;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    padding: 10px;
    border-radius: 5px;
}

.agreement {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    z-index: 1000;
}

.agreement input[type="checkbox"] {
    margin-right: 8px;
    z-index: 1000;
}

.agreement a {
    color: #521004;
    text-decoration: underline;
    z-index: 1000;
}

.pay-button {
    width: 100%;
    padding: 15px;
    background-color:   #050505a4;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
    z-index: 1000;
}

.pay-button:hover {
    background-color: #050505a4;
    z-index: 1000;
}

footer {
    margin-top: 15px;
    font-size: 12px;
    color: #a3a3c3;
    text-align: center;
    z-index: 1000;
}

