/* Footer Container */
.footer {
    color: #FFFFFF;
    text-align: center;
    padding: 20px 0;
    border-top-left-radius: 20px;
    position: relative;
}

/* Footer Stats Section */
.footer-stats {
    display: flex;
    justify-content: space-around;
    background: linear-gradient(to right, #3d3c3c, #1a1a1a);
    margin-bottom: 20px;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
}

.stat {
    text-align: center;
}

.stat-number {
    font-size: 24px;
    font-weight: bold;
}

.stat-label {
    font-size: 14px;
}

/* Footer Wrapper */
.footer-wrapper {
    background-color: #101117;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    margin: auto;
    position: relative;
}

/* Footer Content */
.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 20px;
    border-bottom: 1px solid #2c2c2c;
    margin-bottom: 20px;
}

.footer-logo-section {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.footer-logo {
    flex-shrink: 0;
}

.main-logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.footer-copyright {
    font-family: 'Noto Sans', sans-serif;
    font-size: 10px;
    line-height: 1.5;
    color: #515765;
}

/* Footer Social Section */
.footer-social {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.social-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.social-icons span {
    font-size: 14px;
    color: #c1c1c1;
    margin-right: 10px;
}

.social-icons a img {
    width: 30px;
    height: 30px;
    transition: transform 0.3s;
}

.social-icons a img:hover {
    transform: scale(1.1);
}

.footer-links-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding-top: 20px;
}

.footer-left {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between links, info, and payment icons */
}

.footer-links {
    display: flex;
    gap: 15px;
    font-size: 12px;
}

.footer-links a {
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #FFC107;
}

.footer-info {
    font-size: 12px;
    color: #c1c1c1;
    line-height: 1.5;
}

.payment-methods {
    display: flex;
    gap: 10px; /* Spacing between payment icons */
    margin-top: 10px;
}

.payment-methods img {
    height: 25px;
    transition: transform 0.3s;
}

.payment-methods img:hover {
    transform: scale(1.1);
}

/* Footer Right Section */
.footer-right {
    display: flex;
    flex-direction: column; /* Stack SSL/Steam and Support Button vertically */
    align-items: flex-end; /* Align everything to the right */
    gap: 15px; /* Space between SSL/Steam and Support Button */
}

/* SSL and Steam Container */
.ssl-steam-container {
    display: flex;
    align-items: center; /* Vertically align SSL info and Steam button */
    justify-content: flex-end; /* Align to the right */
    gap: 20px; /* Space between SSL info and Steam button */
    width: 100%; /* Ensure it spans the available width */
}

/* SSL Info */
.ssl-info {
    display: flex;
    align-items: flex-start; /* Align icon and text vertically */
    text-align: left; /* Align the text to the left */
    gap: 8px; /* Space between the icon and text */
    font-size: 12px; /* Increase font size for better readability */
    color: #c1c1c1; /* Match the gray text color */
    line-height: 1.5; /* Add line height for better spacing */
}

.ssl-info img {
    width: 24px; /* Adjust icon size for better visibility */
    height: auto;
}

/* Steam Button */
.steam-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.steam-button img {
    max-width: 180px; /* Ensure the Steam button matches the design */
    height: auto;
}
/* Support Button Container */
/* .support-button {
    position: absolute; 
    bottom: 0; 
    right: 0; 
    padding: 0;
    text-align: right;
}

.support-button button {
    background-color: #282828; 
    color: #FFFFFF; 
    font-size: 14px; 
    font-weight: bold;
    padding: 8px 24px;
    border: none; 
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); 
}

.support-button button:hover {
    background-color: #3a3a3a; 
    border-color: #5E98D9;
    transform: scale(1.05); 
} */
