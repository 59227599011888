.winners {
  position: relative;
  overflow: hidden; /* Hide overflowing content */
  width: 100vw;    /* Width of the main container */
  height: 120px;     /* Match the height of the winners div */
  margin-top: 80px;
  z-index: 1000;
  border-bottom: 1px solid #717070c5;
  box-shadow: 0 8px 10px rgba(110, 110, 110, 0.221);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.slider {
  display: flex;
  /* transition: transform 0.5s ease; Smooth transition for sliding effect */
  width: 100vw;
  gap: 20px;
}

.slide-top {
  width: 80px;      /* Width of each slide */
  height: 80px;      /* Match the height of the winners div */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;          /* Text color */
  margin-top: 20px;
}
.slide-top img {
  height: 80px;
  width: 80px;
  border:1px solid #636363c5;
  border-radius: 20px;
}
.slider-content{
  width: 150px;
}