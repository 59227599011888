.chat-modal {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 300px;
    height: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    z-index: 10000;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
}

.chat-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

.close-chat-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 20px;
    color: #333;
}

.chat-conversation {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
    z-index: 1000;
}

.message-user-message,
.message-answer,
.message-question {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
}

.message-user-message {
    background-color: #e0f7fa;
    align-self: flex-end;
}

.message-answer {
    background-color: #eeeeee;
    align-self: flex-start;
}

.message-question {
    background-color: #f1f8e9;
    cursor: pointer;
    align-self: flex-start;
}

.chat-reply-section {
    padding: 10px;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
}

.chat-reply-section textarea {
    resize: none;
    width: 100%;
    height: 60px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
}

.chat-send-button,
.chat-finish-button {
    padding: 10px;
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.chat-send-button {
    background-color: #007bff;
    color: #ffffff;
}

.chat-finish-button {
    background-color: #dc3545;
    color: #ffffff;
    margin-top: 5px;
}

.chat-send-button:hover,
.chat-finish-button:hover {
    opacity: 0.9;
}

.question{
    color:black;
}
