.user-profile-section {
    /* background-color: rgba(30, 30, 30, 0.9); Background color */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding around the section */
    margin: 20px; /* Margin around the section */
    position: relative;
    background-color: rgba(0, 0, 0, 0.322);
}
.user-card{
    position: relative;
    width: 25%;
}

.user-profile-card {
    display: flex;
    align-items: center; /* Align items vertically centered */
    justify-content: space-between; /* Space out items */
    height: 150px; /* Fixed height for the profile card */
    border-radius: 10px; /* Rounded corners for the card */
    padding: 10px; /* Padding inside the card */
}

.user-avatar {
    width: 150px; /* Set width for the avatar */
    height: 150px; /* Set height for the avatar */
    background: linear-gradient(135deg, #f9220a, #1d1d1d); /* Change colors here */
    border-radius: 50%; /* Make it circular */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the letter in the avatar */
    color: #FFFFFF; /* Letter color */
    font-size: 28px; /* Font size for the letter */
    font-weight: bold; /* Font weight for the letter */
    margin-right: 15px; /* Space between the avatar and user info */
    float: left;
}

.avatar-initial {
    font-size: 28px; /* Font size for the letter inside avatar */
}

.user-details {
    display: flex;
    flex-direction: column; /* Stack the username and button vertically */
    justify-content: center; /* Center items in the user info section */
}

.user-name {
    font-size: 20px; /* Size for the username */
    font-weight: 600; /* Weight for the username */
    color: #FFFFFF; /* Color for the username */
}
.progress-container {
    width: 100%; /* Full width of the container */
    margin-top: 20px;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    border-radius: 5px; /* Rounded corners */
    background-color: transparent; /* Light gray background */
    overflow: hidden; /* Ensure no overflow */
}

.progress-bar {
    height: 30px; /* Height of the progress bar */
    background: linear-gradient(135deg, #f9220a, #1d1d1d); 
    color: white; /* Text color */
    text-align: center; /* Center the text */
    line-height: 30px; /* Center the text vertically */
    font-weight: bold; /* Bold text */
    transition: width 0.5s; /* Smooth transition for width change */
}

.how-it-works{
    color: #d4950b;
}

.level-up-btn {
    padding: 8px 15px; /* Padding for the button */
    /* border: 1px solid #684343c5; */
    border: none;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    color: white; /* Button text color */
    border-radius: 5px; /* Rounded corners for the button */
    cursor: pointer; /* Pointer on hover */
    font-size: 14px; /* Font size for the button text */
    margin-top: 5px; /* Space above level-up button */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.9), rgba(236, 8, 8, 0.5));
}

.experience-info {
    font-size: 14px; /* Font size for the XP info */
    color: #FFFFFF; /* Color for the XP info */
    margin-top: 5px; /* Margin above XP info */
}

.user-balances {
    position: relative;
    width: 20%;
    /* display: flex; Use flexbox for balances */
    /* width: 150px; */
    height: 150px;
    justify-content: space-between; /* Space between balance items */
    margin-top: 50px; /* Space above balances */
}

.balance {
    width: 90%;
    padding: 8px 15px;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.9), rgba(236, 8, 8, 0.5));
    color: #FFFFFF; /* Color for balance text */
    font-weight: bold; /* Bold weight for amount */
    margin-top: 10px;
    text-align: center;
    border: 1px solid #684343c5;
    border-radius: 10px;
    /* background-color: transparent; */
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
}
.fill-ballance {
    padding: 8px 10px;
    border-radius: 10px;
    margin-top: 10px;
    color: white;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.9), rgba(236, 8, 8, 0.5));
}

.confirm-account-btn:hover, .partner-program-btn:hover, .fill-ballance:hover, .apply-btn:hover, .level-up-btn:hover {
    background: linear-gradient(135deg, #f9220a, #1d1d1d); /* Hover gradient */
}

.account-info {
    position: relative;
    width: 20%;
    height: 150px;
}

.account-status {
    color: rgb(153, 2, 2);
    text-align: center;
    font-size: 16px;
}

.confirm-account-btn, .partner-program-btn {
    position: relative;
    width: 100%;
    padding: 8px 10px;
    border-radius: 10px;
    margin-top: 10px;
    color: white;
    text-align: center;
    border: 1px solid #684343c5;
    /* background-color: transparent; */
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.9), rgba(236, 8, 8, 0.5));
}

.steam-login-input {
    position: relative;
    margin-top: 50px;
    width: 20%;
    height: 150px;
}

.steam-login-input input {
    width: 100%;
    padding: 8px 15px; /* Padding inside input */
    border: 1px solid #444; /* Border around input */
    border-radius: 25px; /* Rounded corners for input */
    flex: 1; /* Take up remaining space */
    margin-top: 10px;
    text-align: center;
    border: 1px solid #684343c5;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
}

.apply-btn {
    margin-top: 10px;
    padding: 5px 10px; /* Padding for apply button */
    color: white; /* Button text color */
    border-radius: 10px; /* Rounded corners */
    text-align: center;
    border: 1px solid #684343c5;
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.9), rgba(236, 8, 8, 0.5));
}

.tab-buttons {
    margin-top: 60px; /* Margin above tabs */
    position: relative;
    padding: 5px;
    background: rgba(0, 0, 0, 0.7);
}

.tab-btn {
    color: white; /* Tab text color */
    width: 250px;
    padding: 10px 20px; /* Padding for tabs */
    cursor: pointer; /* Pointer on hover */
    margin-right: 10px; /* Space between tabs */
    text-align: center;
    border: 1px solid #684343c5;
    border-radius: 10px;
    background-color:  rgba(236, 8, 8, 0.5);
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
}

.tab-btn.active {
    color: white; /* Active tab text color */
    background: linear-gradient(135deg, #f9220a, #1d1d1d); /* Change colors here */
}

.tab-btn:hover {
    background: linear-gradient(135deg, #f9220a, #1d1d1d); /* Change colors here */
}

.items-display {
    display: flex;
    flex-direction: row;
    color: #FFFFFF; /* Text color for items section */
    flex-wrap: wrap;
    gap: 30px;
    justify-content: left;
    padding-top: 20px;
}

.item-container {
    width: 200px; /* Adjust as needed */
    text-align: center;
    margin-top: 20px;
}

.image-container {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    position: relative;
    overflow: hidden;
    perspective: 1000px; /* Enable 3D perspective */
}

.image-container img, .image-container .info {
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hide the back when rotated */
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.6s, opacity 0.6s; /* Smooth transition for both */
}

.image-container img {
    transform: rotateY(0deg); /* Default position */
}

.image-container .info {
    background-color: rgba(0, 0, 0, 0.8); /* Background color for info */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(180deg); /* Rotate out of view by default */
    opacity: 0; /* Start hidden */
}

.image-container:hover img {
    transform: rotateY(180deg); /* Rotate image on hover */
}

.image-container:hover .info {
    transform: rotateY(0deg); /* Rotate info into view */
    opacity: 1; /* Show the info */
}
