
/* General Desktop Styles */
.content-header {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.623);
    position: fixed;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    border-bottom: 1px solid #717070c5;
    box-shadow: 0 8px 10px rgba(255, 255, 255, 0.349);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    gap: 30px;
}
.scrolled{
    background-color: rgba(0, 0, 0, 0.922);
    z-index: 100000;
}

/* Logo */
.logo img {
    width: 50px;
    height: 50px;
}

.logo {
    width: 50px;
    height: 50px;
}

/* Navigation Menu for larger screens (default) */

.main-logo {
    border-radius: 10px;
}

.menu-container {
    z-index: 1000;
    display: flex;
    flex-direction: row;
    float: right;
    top: 0px;
    gap: 10px;
}

.avatar{
    width:40px;
    height:40px;
}
.avatar img {
    width:40px;
    height:40px;
}
.username{
    font-size: 11px;
}