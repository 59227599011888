.common-button {
    padding: 5px;
    background: linear-gradient(0deg, #000000, #000000);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    perspective: 300px;
    transition: transform 0.3s ease-out;
    position: relative;
    overflow: hidden;
    /* width: 150px; */
}

.common-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(135deg, #f9220a, #1d1d1d); /* Change colors here */
    transition: background 0.5s ease-in-out;
    z-index: -1;
}

.common-button:hover::before {
    left: 0;
    animation: gradientShift 0.5s forwards;
}

@keyframes gradientShift {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

.common-button span {
    color: #fff;
    font-size: 20px;
    z-index: 1;
    padding: 5px;
    margin-right: 15px;
}

.common-button img {
    width: 40px;
    /* height: 30px; */
    /* border-top-left-radius: 10px; */
    /* border-bottom-left-radius: 10px; */
    border-radius: 10px;
    object-fit: cover;
    z-index: 1;
    /* margin: 0px; */
}

.common-button-login {
    padding: 5px;
    background: linear-gradient(135deg, #f9220a, #1d1d1d); /* Change colors here */
    border-radius: 10px;
    /* display: flex; */
    /* flex-direction: row; */
    gap: 10px;
    height: 40px;
    justify-content: center;
    perspective: 300px;
    display: inline;
    transition: transform 0.3s ease-out;
    /* position: fixed; */
    overflow: hidden;
    font-size: 12px;
    color: white;
    float: right;
    position: absolute;
    margin-right: 20px;
    right: 0;
    width: 100px;
    justify-content: center;
    text-align: center;
}

.common-button-login span {
    line-height: 30px;
    font-weight: bold;
}
