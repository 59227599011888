.title{
    z-index: 100;
    font-size: 56px;
    font-weight: bold;
    color: white;
    margin: 20px;
}
.main-p{
    font-size: 18px;
    color: rgb(201, 189, 189);
    margin: 20px;
}
.logo-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.hero-section {
    height: 50vh;
    /* background: url('images/background.webp') no-repeat center center/cover; */
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: relative;
}

.hero-content {
    z-index: 2;
    text-align: center;
    margin-bottom: 50px;
}

.hero-content h1 {
    font-size: 2em;
    padding: 0px;
    margin: 0px;
    color: var(--primary-color);
}

.hero-content p {
    font-size: 1.5em;
    margin: 10px 0;
}

.space {
    height: 80px;
}

/* Section styling */
.section {
    padding: 60px;
    text-align: center;
}

.hero-section {
    height: 40vh;
    width: 100vw;
    /* background: url('images/background.webp') repeat-x center/contain; */
    position: relative;
    top: 20px;
    display: flex;
    justify-content: center;
    /* align-items: end; */
    text-align: center;
}

.section-intro {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 40px 20px;
    text-align: center;
}

.section-intro h2 {
    font-size: 3em;
    color: #FFD700;
}

/* HOW TO PLAY */
.how-to-play-section {
    
    /* background-color: #1d333dc5; */
    /* background: linear-gradient(45deg, #5e027a86, #00000067); */
    /* background-image: url(images/case_theme.webp); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background: url('images/how-to-play.webp') no-repeat center center/cover; */
    color: white;
    padding: 60px 20px;
    text-align: center;
    position: relative;
}

.how-to-play-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 1;
}

.how-to-play-section h2, .steps {
    position: relative;
    z-index: 2;
}

.steps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.step {
    /* background-color: rgba(51, 51, 51, 0.8); */
    padding: 0px;
    border-radius: 10px;
    width: 250px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */
    transition: transform 0.3s ease;
}

.step img {
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    height: auto;
}

.info-box {
        /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4); */
        border-radius: 20px;
}

.step h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #ffffff;
}

.step p {
    font-size: 1.1em;
    line-height: 1.5;
    margin: 5px;
    padding: 10px;
    background: linear-gradient(135deg, #f9220a, #360c42f5, #504f4f4d, #f9220a);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.step:hover {
    transform: translateY(-10px);
}

/* CSS for smaller screens */

@media screen and (max-width: 768px) {
    /* General adjustments */
    body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }

    /* Ensure all sections stay within the viewport */
    .section, .hero-section, .how-to-play-section, .join-us-section, #roadmap {
        width: 100vw; /* Ensure sections take up the full width */
        overflow-x: hidden; /* Prevent any horizontal overflow */
    }

    /* Cards in Join Us section */
    .cards {
        flex-direction: column;
        align-items: center; /* Ensure cards are centered */
        gap: 20px;
    }

    .card {
        width: 90%; /* Ensure the cards don't overflow */
        max-width: 300px;
        margin: 0 auto; /* Center the cards */
    }
    
    /* Make sure images don't extend beyond the viewport */
    img {
        max-width: 100%;
        height: auto;
    }
    
    .hero-content h1 {
        font-size: 2em;
    }

    .hero-content p {
        font-size: 1em;
    }

    /* Reduce padding/margin for small screen elements */
    .cta-btn {
        width: auto;
        padding: 20px 40px;
        margin: 0 auto;
    }

    .steps {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .step {
        width: 90%; /* Reduce width for steps */
        max-width: 300px;
    }

    .timeline, .phase {
        width: 100%; /* Ensure full-width on mobile */
    }

    /* Make sure there is no extra margin or padding on small screens */
    .phase-left, .phase-right {
        align-self: center;
        margin: 0;
    }

    .hero-content {
        margin-bottom: 50px;
    }

    footer {
        width: 100vw; /* Ensure footer takes full width */
    }
}

/* Extra small screens (below 480px) */
@media screen and (max-width: 480px) {
    /* Further width and padding adjustments */
    .cta-btn {
        padding: 15px 30px;
    }

    .steps {
        width: 100%;
    }

    .step {
        width: 100%;
    }

    .cards {
        width: 100%;
        /* gap: 20px; */
    }

    .card {
        width: 95%;
    }

    .phase {
        width: 100%;
    }

    .timeline {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 20px;
    }

    .phase-left {
        align-self: flex-start;
        margin-right: auto; /* Aligns phase to the left */
    }

    .phase-right {
        align-self: flex-end;
        margin-left: auto; /* Aligns phase to the right */
    }

    .hero-content h1 {
        font-size: 2em;
    }

    .hero-content p {
        font-size: 1em;
    }
}

.item img{
    width: 100%;
}
.item {
    width: 100vw;
    height: 100vh;
}
.carousel .slide{
    width: 100vw;
    position: relative;
    overflow: hidden;
    /* height: 100vh; */
}

/* profile */
.profile-section {
    display: flex;
    align-items: center; /* Vertically center items */
    height: 65px;
    border-radius: 10px; /* Optional rounded corners */
    position: absolute; /* Positioning */
    /* padding: 8px 0; */
    right: 30px; /* Distance from the right edge */
    top: 5px; /* Optional distance from the top edge */
}

.avatar {
    flex-shrink: 0; /* Prevents the avatar from shrinking */
    width: 55px; /* Fixed width */
    height: 55px; /* Full height */
    background: linear-gradient(135deg, #f9220a, #1d1d1d); /* Change colors here */
    border-radius: 50%; /* Circular avatar */
    display: flex;
    align-items: center; /* Center letter vertically */
    justify-content: center; /* Center letter horizontally */
    font-size: 24px; /* Font size for the letter */
    color: white; /* Color of the letter */
}
.notification-badge {
    position: absolute;
    top: 35px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
  }
  
  
.user-info {
    flex-grow: 1; /* Allows user info to take available space */
    margin-left: 10px; /* Space between avatar and user info */
    position: relative;
    /* flex-direction: column; Arrange elements vertically */
}

.user-info a{
    text-decoration: none;  /* Remove underline */
    color: inherit;
}

.logout-image{
    position: relative;
    width: 20px;
    height: 20px;
    float: left;
    cursor: pointer;
    margin-top: -35px;
    margin-left: 10px;
}

.logout-image img{
    width: 20px;
    height: 20px;
}

.username {
    color: white; /* Text color */
    font-size: 16px; /* Font size for username */
    float: left;
}

.balance-button {
    background-color: transparent; /* Button background color */
    box-shadow: 0 8px 10px rgba(139, 6, 6, 0.507);
    color: white; /* Button text color */
    border: 1px solid #684343c5;
    border-radius: 5px;
    padding: 5px 10px; /* Button padding */
    cursor: pointer; /* Pointer cursor on hover */
    margin-right: 10px;
}

.balances {
    /* display: flex; */
    /* float: left; */
    justify-content: space-between; /* Space balances evenly */
    margin-top: 15px; /* Space between button and balances */
    gap: 10px;
}

.balances div {
    color: white; /* Color for balances */
    font-size: 16px; /* Font size for balances */
    
}
