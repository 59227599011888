/* Global settings to prevent overflow and ensure box-sizing */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensure padding and borders are included in width/height */
}

html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari, Opera) */
body::-webkit-scrollbar {
    display: none;
}
/* Colors based on the game's theme */
:root {
    --primary-color: #5212c9; /* Gold-like for important elements */
    --secondary-color: #000000c5; /* Orange accents */
    --text-color: #FFF; /* Bright text */
    --hover-color: #FFC107; /* Hover effect color */
    --dark-section-bg: #222; /* Darker section background */
    --overlay-color: rgba(0, 0, 0, 0.3); /* Dark overlay */
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    /* background-color: var(--background-color); */
    /* background-image: url(images/DOtA2.webp); */
    color: var(--text-color);
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar in Firefox */
}
.main-image{
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.main-image img {
    width: 100vw;
    height: 100vh;
}
.overlay-main {
    background-color: rgba(0, 0, 0, 0.808);
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    position: fixed; /* Fixes the position relative to the viewport */
    top: 0;;
}
