/* src/styles/modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    background: linear-gradient(135deg, #000000, #8B0000);
    background-size: cover;
    background-blend-mode: overlay;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.google {
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor:'pointer';
    background-color:black;
    color: #fff;
    font-size: 16px;
    display:flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.9);
}

.steam{
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #171A21;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.9);
}
.vk{
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #171A21;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.9);
}

.login-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
