
.line-container {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    margin: 20px 0; /* Add some margin for spacing */
}

.line {
    height: 2px; /* Line thickness */
    margin: 0 10px; /* Space between the line and text */
    z-index: 1000;
}

.left-line {
    flex-grow: 1; /* Allow the left line to grow */
    background: linear-gradient(270deg, #a82b0c, #050727); /* Gradient for left line */
    height: 4px; /* Line thickness */
    z-index: 1000;
}

.right-line {
    flex-grow: 1; /* Allow the right line to grow */
    background: linear-gradient(90deg, #a82b0c, #050727); /* Same gradient for right line */
    height: 4px; /* Line thickness */
    z-index: 1000;
}

.line-text {
    font-size: 38px; /* Set font size */
    color: rgb(201, 189, 189);
    font-weight: bold; /* Make text bold */
    white-space: nowrap; /* Prevent text from wrapping */
    text-align: center; /* Center the text */
    z-index: 1000;
}